const SOP = () => {
  return (
    <section className="pt-12 md:pt-24 pb-12 md:pb-24" id="sop">
      <div className="container">
        <div className="w-full px-4">
          <h1 className="font-semibold text-2xl text-primary text-center mb-8 md:text-4xl md:mb-16">
            Standar Operasional Prosedur
          </h1>
          <div className="flex flex-col md:flex-row items-center md:items-start justify-between lg:w-11/12 md:max-w-5xl mx-auto">
            <div className="mb-12">
              <img
                className="mx-auto mb-4"
                src="/img/permohonan.svg"
                alt="permohonan"
              />
              <div className="flex md:flex-col items-center">
                <div className="rounded-full w-6 h-6 md:w-10 md:h-10 flex mr-2 md:mb-4 bg-primary">
                  <span className=" text-white m-auto">1</span>
                </div>
                <div className="text-lg">Pengajuan Permohonan</div>
              </div>
            </div>
            <div className="mb-12">
              <img
                className="mx-auto mb-4"
                src="/img/pemeriksaan-dokumen.png"
                alt="pemeriksaan dokumen"
              />
              <div className="flex md:flex-col items-center">
                <div className="rounded-full w-6 h-6 md:w-10 md:h-10 flex mr-2 md:mb-4 bg-primary">
                  <span className=" text-white m-auto">2</span>
                </div>
                <div className="text-lg md:max-w-[10ch] text-center lg:max-w-full">
                  Pemeriksaan Dokumen
                </div>
              </div>
            </div>
            <div className="mb-12">
              <img
                className="mx-auto mb-4"
                src="/img/survei-lapangan.svg"
                alt="survei lapangan"
              />
              <div className="flex md:flex-col items-center">
                <div className="rounded-full w-6 h-6 md:w-10 md:h-10 flex mr-2 md:mb-4 bg-primary">
                  <span className=" text-white m-auto">3</span>
                </div>
                <div className="text-lg md:max-w-[10ch] text-center lg:max-w-full">
                  Survei Lapangan
                </div>
              </div>
            </div>
            <div className="mb-12">
              <img
                className="mx-auto mb-4"
                src="/img/penerbitan-surat-skko.svg"
                alt="penerbitan surat skko"
              />
              <div className="flex md:flex-col items-center">
                <div className="rounded-full w-6 h-6 md:w-10 md:h-10 flex mr-2 md:mb-4 bg-primary">
                  <span className=" text-white m-auto">4</span>
                </div>
                <div className="text-lg md:max-w-[10ch] text-center lg:max-w-full">
                  Penerbitan Surat SKKO
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SOP;
