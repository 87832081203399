import Swal from "sweetalert2";

export const showModalErrorRedirect = (setPage) => {
  let timerInterval;
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Terjadi Kesalahan!",
    timer: 2000,
    timerProgressBar: true,
    willClose: () => {
      clearInterval(timerInterval);
      setPage((curr) => curr - 1);
    },
  });
};

export const showModalError = () => {
  let timerInterval;
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Terjadi Kesalahan!",
    timer: 2000,
    timerProgressBar: true,
    willClose: () => {
      clearInterval(timerInterval);
    },
  });
};
