import { useState, useEffect } from "react";
import { showModalError } from "../Form/modalError";
import { API_AMOR } from "../../config";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Dokumen = () => {
  const [persyaratan, setPersyaratan] = useState([]);

  useEffect(() => {
    const url = `${API_AMOR}/persyaratan`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setPersyaratan(json.data);
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }, []);

  return (
    <section className="pt-12 md:pt-24 pb-12 md:pb-24" id="dokumen">
      <div className="container">
        <div className="flex items-center">
          <div className="w-full px-4">
            <h1 className="font-semibold text-2xl text-primary text-center mb-8 md:text-4xl md:mb-16">
              Dokumen Permohonan
            </h1>
            <div className="flex justify-between items-center">
              <div className="w-full lg:w-1/2">
                {persyaratan ? (
                  persyaratan.map((item) => (
                    <div className="flex relative mb-6" key={item.id}>
                      <div className="bg-primary w-8 h-8 flex absolute -left-4 top-1/2 -translate-y-1/2 items-center">
                        <span className="mx-auto text-white">{item.id}</span>
                      </div>
                      <div className="border-2 border-primary rounded-md px-8 py-4 text-left text-secondary w-full leading-relaxed">
                        {item.persyaratan}
                      </div>
                    </div>
                  ))
                ) : (
                  <Skeleton
                    count={7}
                    className="h-12 my-2"
                    borderRadius="1.25rem"
                  />
                )}
              </div>
              <div className="w-1/2 hidden lg:block">
                <img className="m-auto w-5/6" src="/img/hero.jpg" alt="hero" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dokumen;
