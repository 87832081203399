import React, { useContext } from "react";
import { FilesContext } from ".";

const Berkas = ({ register, errors, setFiles, errorAPI }) => {
  const files = useContext(FilesContext);

  return (
    <>
      <div className="mb-8">
        <h4 className="font-semibold">Catatan:</h4>
        <ul>
          <li className="text-xs text-primary mb-1">
            • Input dengan label bertanda * wajib diisi
          </li>
          <li className="text-xs text-primary mb-1">
            • Format berkas harus berekstensi .jpg, .jpeg, .png, atau .pdf
          </li>
          <li className="text-xs text-primary mb-1">
            • Ukuran berkas tidak boleh melebihi 2 MB
          </li>
        </ul>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_1" className="required mb-4 text-sm md:text-base">
          Surat Permohonan Penerbitan Keterangan Keberadaan Organisasi ke Kepala
          Badan Kesatuan Bangsa dan Politik:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="file_1"
            {...register("file_1")}
            onChange={(e) => {
              setFiles({ ...files, file_1: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_1"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {files.file_1}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_1?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_1 && errorAPI.file_1[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_2" className="required mb-4 text-sm md:text-base">
          SK Kemenhumham atau SKT dari Kemendagri:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="file_2"
            {...register("file_2")}
            onChange={(e) => {
              setFiles({ ...files, file_2: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_2"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {files.file_2}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_2?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_2 && errorAPI.file_2[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_3" className="required mb-4 text-sm md:text-base">
          Akte Pendirian Orkesmas yang disahkan notaris:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="file_3"
            {...register("file_3")}
            onChange={(e) => {
              setFiles({ ...files, file_3: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_3"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {files.file_3}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_3?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_3 && errorAPI.file_3[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_4" className="required mb-4 text-sm md:text-base">
          Surat Keputusan tentang susunan Pengurus Orkesmas secara lengkap dan
          sah:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="file_4"
            {...register("file_4")}
            onChange={(e) => {
              setFiles({ ...files, file_4: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_4"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {files.file_4}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_4?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_4 && errorAPI.file_4[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_5" className="required mb-4 text-sm md:text-base">
          Fotocopy Kartu Tanda Penduduk Pengurus Organisasi:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="file_5"
            {...register("file_5")}
            onChange={(e) => {
              setFiles({ ...files, file_5: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_5"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {files.file_5}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_5?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_5 && errorAPI.file_5[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_6" className="required mb-4 text-sm md:text-base">
          Surat Keterangan Domisili Organisasi dari Kepala Desa/Lurah/Camat/atau
          sebutan lainnya:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0 "
            id="file_6"
            {...register("file_6")}
            onChange={(e) => {
              setFiles({ ...files, file_6: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_6"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate inline-block overflow-x-scroll overflow-y-hidden"
          >
            {files.file_6}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_6?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_6 && errorAPI.file_6[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="file_7" className="required mb-4 text-sm md:text-base">
          Foto Kantor atau sekretariat Orkesmas, tampak depan yang memuat papan
          nama:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="file_7"
            {...register("file_7")}
            onChange={(e) => {
              setFiles({ ...files, file_7: e.target.files[0].name });
            }}
          />
          <label
            htmlFor="file_7"
            className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {files.file_7}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.file_7?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.file_7 && errorAPI.file_7[0]}
          </p>
        </div>
      </div>
    </>
  );
};

export default Berkas;
