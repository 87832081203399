import { Link } from "react-router-dom";

const Tagline = ({ showButton }) => {
  return (
    <section
      className={`${
        !showButton
          ? "pt-16 md:pt-24 pb-16 md:pb-16"
          : "pt-16 md:pt-32 pb-16 md:pb-28"
      } bg-primary`}
      id={!showButton ? "home" : ""}
    >
      <div className="container">
        <div className="w-full px-4 flex flex-col">
          <h4 className="font-semibold text-xl text-white mx-auto text-center leading-loose mb-12 max-w-xl">
            Ajukan permohonan organisasi kemasyarakatan untuk kemudahan
            pendataan
          </h4>
          {showButton ? (
            <Link
              to="/permohonan"
              className="bg-white border-2 border-white  rounded-lg font-semibold text-sm text-primary px-8 py-3 mx-auto hover:bg-primary hover:text-white hover:border-white hover:border-2 focus:bg-primary focus:text-white focus:border-white focus:border-2 focus:outline-none transition duration-300"
            >
              Ajukan Permohonan
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};

export default Tagline;
