export const prepareFormData = (data) => {
  const formData = new FormData();

  if (data.logo[0]) {
    formData.append("logo", data.logo[0]);
  }

  formData.append("nama_organisasi", data.nama_organisasi);
  formData.append("nama_ketua", data.nama_ketua);
  formData.append("alamat", data.alamat);
  formData.append("kelurahan", data.kelurahan);
  formData.append("kecamatan", data.kecamatan);
  formData.append("no_hp", data.no_hp);
  formData.append("email", data.email);
  formData.append("no_akta", data.no_akta);
  formData.append("berbadan_hukum", data.berbadan_hukum);
  formData.append("file_1", data.file_1[0]);
  formData.append("file_2", data.file_2[0]);
  formData.append("file_3", data.file_3[0]);
  formData.append("file_4", data.file_4[0]);
  formData.append("file_5", data.file_5[0]);
  formData.append("file_6", data.file_6[0]);
  formData.append("file_7", data.file_7[0]);

  return formData;
};
