import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = ({ page }) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/js/script.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <header className="bg-transparent absolute top-0 left-0 z-10 w-full flex items-center">
      <div className="container">
        <div className="flex items-center justify-between relative">
          <div className="px-4">
            <a
              href="#home"
              className="font-bold text-2xl text-primary block py-2"
            >
              <img
                className="m-auto w-[80px] md:w-[100px]"
                src="/img/logo-amor.png"
                alt="logo amor"
              />
            </a>
          </div>
          <div className="flex items-center px-4">
            <button
              type="button"
              className={`block absolute right-4 lg:hidden focus:outline-none focus:opacity-80 ${
                isMenuActive ? "hamburger-active" : ""
              }`}
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              <span className="hamburger-line transition duration-300 ease-in-out origin-top-left"></span>
              <span className="hamburger-line transition duration-300 ease-in-out "></span>
              <span className="hamburger-line transition duration-300 ease-in-out origin-bottom-left"></span>
            </button>

            <nav
              id="nav-menu"
              className={`${
                isMenuActive ? "" : "hidden"
              }  absolute py-5 bg-white shadow-lg rounded-lg max-w-[250px] w-full right-4 top-full lg:block lg:static lg:bg-transparent lg:max-w-full lg:shadow-none lg:rounded-none`}
            >
              {page === "home" ? (
                <ul className="block lg:flex">
                  <li className="group">
                    <a
                      href="#home"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      Beranda
                    </a>
                  </li>
                  <li className="group">
                    <a
                      href="#sop"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      SOP
                    </a>
                  </li>
                  <li className="group">
                    <a
                      href="#dokumen"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      Dokumen
                    </a>
                  </li>
                  <li className="group">
                    <a
                      href="#ormas"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      Ormas
                    </a>
                  </li>
                  <li className="group">
                    <a
                      href="#hubungi"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      Hubungi
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="block lg:flex">
                  <li className="group">
                    <Link
                      to="/"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      Beranda
                    </Link>
                  </li>
                  <li className="group">
                    <a
                      href="#home"
                      className="text-base py-2 mx-8 flex group-hover:text-primary lg:font-semibold focus:outline-none focus:text-primary"
                    >
                      Permohonan
                    </a>
                  </li>
                </ul>
              )}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
