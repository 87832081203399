import React, { useState } from "react";
import Swal from "sweetalert2";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Identitas from "./identitas";
import Berkas from "./berkas";
import Loader from "../Loader";

import { validationSchema } from "./validationSchema";
import { prepareFormData } from "./formData";
import { showModalErrorRedirect } from "./modalError";
import { API_AMOR } from "../../config";

export const FilesContext = React.createContext();
export const SelectedKecamatanContext = React.createContext();
export const SelectedKelurahanContext = React.createContext();
export const LogoLabelContext = React.createContext();

const Form = () => {
  const defaultLabelFiles = {
    file_1: "Upload berkas",
    file_2: "Upload berkas",
    file_3: "Upload berkas",
    file_4: "Upload berkas",
    file_5: "Upload berkas",
    file_6: "Upload berkas",
    file_7: "Upload berkas",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedKecamatan, setSelectedKecamatan] = useState();
  const [selectedKelurahan, setSelectedKelurahan] = useState();
  const [page, setPage] = useState(0);
  const [files, setFiles] = useState(defaultLabelFiles);
  const [defaultLogoLabel, setDefaultLogoLabel] = useState("Upload Logo");
  const [errorAPI, setErrorAPI] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const renderForm = () => {
    if (page === 0) {
      return (
        <Identitas
          register={register}
          errors={errors}
          setSelectedKecamatan={setSelectedKecamatan}
          setSelectedKelurahan={setSelectedKelurahan}
          errorAPI={errorAPI}
          setDefaultLogoLabel={setDefaultLogoLabel}
        />
      );
    } else {
      return (
        <Berkas
          register={register}
          errors={errors}
          setFiles={setFiles}
          errorAPI={errorAPI}
        />
      );
    }
  };

  const onSubmit = async (data) => {
    const url = `${API_AMOR}/permohonan`;

    const formData = prepareFormData(data);

    setIsLoading((curr) => !curr);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.status === 422) {
        const err = await response.json();
        setErrorAPI(err);
        setIsLoading((curr) => !curr);
        return;
      }

      if (response.status >= 400) {
        showModalErrorRedirect(setPage);
        setIsLoading((curr) => !curr);
        return;
      }

      setFiles(defaultLabelFiles);
      setDefaultLogoLabel("Upload Logo");
      setSelectedKecamatan("");
      setSelectedKelurahan("");
      setErrorAPI({});
      reset();
      let timerInterval;
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        html: "Permohonan Berhasil Dibuat",
        timer: 3000,
        timerProgressBar: true,
        willClose: () => {
          setPage((curr) => curr - 1);
          clearInterval(timerInterval);
        },
      });
    } catch (err) {
      showModalErrorRedirect(setPage);
    }
    setIsLoading((curr) => !curr);
  };

  return (
    <section className="pt-12 md:pt-24 pb-12 md:pb-24">
      <div className="container">
        <div className="flex flex-col items-center">
          <div
            className={`${
              page === 0 ? "bg-[#C0C0C0]" : "bg-[#81E5A7]"
            } w-[200px] md:w-[400px] h-1 mb-32 relative`}
          >
            <div className="absolute -translate-x-1/2 -translate-y-[30px]">
              <div
                className={`w-16 h-16 mb-6 flex items-center rounded-full border-2 ${
                  page === 0
                    ? "bg-white border-[#C0C0C0]"
                    : "bg-[#81E5A7] border-[#81E5A7]"
                }`}
              >
                <div
                  className={`mx-auto text-lg md:text-2xl ${
                    page === 0 ? "text-black" : "text-white"
                  }`}
                >
                  1
                </div>
              </div>
              <div className="text-xl">Identitas</div>
            </div>
            <div className="absolute translate-x-1/2 -translate-y-[30px] right-0">
              <div className="w-16 h-16 mb-6 flex items-center rounded-full bg-white border-2 border-[#C0C0C0]">
                <div className="mx-auto text-lg md:text-2xl">2</div>
              </div>
              <div className="text-xl">Berkas</div>
            </div>
          </div>
          <form
            className="w-full md:w-4/5 lg:w-2/5 px-4 mb-8 mx-auto"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FilesContext.Provider value={files}>
              <SelectedKecamatanContext.Provider value={selectedKecamatan}>
                <SelectedKelurahanContext.Provider value={selectedKelurahan}>
                  <LogoLabelContext.Provider value={defaultLogoLabel}>
                    {renderForm()}
                  </LogoLabelContext.Provider>
                </SelectedKelurahanContext.Provider>
              </SelectedKecamatanContext.Provider>
            </FilesContext.Provider>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex justify-between mb-8 mt-10">
                <button
                  className="font-medium text-primary disabled:opacity-60 disabled:cursor-not-allowed"
                  onClick={() => setPage((currPage) => currPage - 1)}
                  disabled={page === 0}
                >
                  {" "}
                  <img
                    src="/img/chevron-right.svg"
                    alt="right-arrow"
                    className="inline rotate-180"
                  />{" "}
                  Sebelumnya
                </button>
                {page === 1 ? (
                  <button className="font-medium text-white bg-primary border-2 border-primary px-8 py-2 rounded-lg disabled:opacity-60 disabled:cursor-not-allowed hover:bg-white hover:text-primary focus:bg-white focus:text-primary focus:border-primary focus:border-2 focus:outline-none transition duration-300">
                    Kirim
                  </button>
                ) : (
                  <button
                    className="font-medium text-primary disabled:opacity-60 disabled:cursor-not-allowed"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage((currPage) => currPage + 1);
                    }}
                  >
                    Berikutnya{" "}
                    <img
                      src="/img/chevron-right.svg"
                      alt="right-arrow"
                      className="inline"
                    />
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Form;
