import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="pt-36 pb-12" id="home">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="w-full px-4 mb-6 md:mb-0 md:order-2">
            <img
              src="/img/hero.jpg"
              alt="permohonan ormas"
              className="w-56 md:w-full md:max-w-full mx-auto"
            />
          </div>
          <div className="w-full px-4 text-center md:text-left md:order-1">
            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl text-primary max-w-sm md:max-w-lg mb-6 md:mb-10 leading-snug mx-auto md:mx-0">
              Permohonan Organisasi Kemasyarakatan Lebih Gampang dan Cepat
            </h1>
            <p className="text-sm md:text-base text-secondary mb-6 md:mb-10 leading-loose md:leading-[32px]">
              Jadikan organisasi kemasyarakatan (ormas) yang berkantor di Kota
              Pangkalpinang terdata di Badan Kesatuan Bangsa dan Politik Kota
              Pangkalpinang.
            </p>
            <Link
              to="/permohonan"
              className="font-semibold text-sm md:text-base text-white bg-primary border-2 border-primary px-8 py-3 rounded-md hover:bg-white hover:border-2 hover:border-primary hover:text-primary focus:bg-white focus:border-2 focus:border-primary focus:text-primary focus:outline-none transition duration-300"
            >
              Ajukan Permohonan
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
