import { useContext } from "react";

// Components
import Navbar from "./Navbar";
import Hero from "./Hero";
import SOP from "./SOP";
import Tagline from "./Tagline";
import Dokumen from "./Dokumen";
import Ormas from "./Ormas";
import Hubungi from "./Hubungi";
import Footer from "./Footer";
import { WebInfoContext } from "../App";

const Home = () => {
  const webInfo = useContext(WebInfoContext);

  return (
    <>
      <Navbar page={"home"} />
      <Hero />
      <SOP />
      <Tagline showButton={true} />
      <Dokumen />
      <Ormas />
      <Hubungi orgInfo={webInfo} />
      <Footer orgInfo={webInfo} />
    </>
  );
};

export default Home;
