import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="h-screen flex items-center">
        <div className="flex flex-col items-center -translate-y-4">
          <img src="/img/404.jpg" alt="404" className="w-4/4 md:w-2/5" />
          <h1 className="font-semibold text-black text-lg md:text-3xl mb-2">
            Halaman Tidak Ditemukan
          </h1>
          <p className="font-light text-black text-md mb-6">
            Silahkan kembali ke beranda
          </p>
          <Link
            to="/"
            className="float-right border-2 border-primary bg-white px-10 py-3 rounded-lg font-semibold text-primary hover:text-white hover:bg-primary duration-300"
          >
            Beranda
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
