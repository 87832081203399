import { useContext } from "react";

// Components
import Navbar from "./Navbar";
import Tagline from "./Tagline";
import Footer from "./Footer";
import Form from "./Form";

import { WebInfoContext } from "../App";

const Permohonan = () => {
  const webInfo = useContext(WebInfoContext);

  return (
    <>
      <Navbar page={"permohonan"} />
      <div className="mt-[80px]">
        <Tagline showButton={false} />
      </div>
      <Form />
      <Footer orgInfo={webInfo} />
    </>
  );
};

export default Permohonan;
