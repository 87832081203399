import { useState, useEffect, useContext } from "react";
import {
  SelectedKecamatanContext,
  SelectedKelurahanContext,
  LogoLabelContext,
} from ".";
import { API_PORTAL } from "../../config";

import { showModalError } from "./modalError";

const Identitas = ({
  register,
  errors,
  setSelectedKecamatan,
  setSelectedKelurahan,
  errorAPI,
  setDefaultLogoLabel,
}) => {
  const selectedKecamatan = useContext(SelectedKecamatanContext);
  const selectedKelurahan = useContext(SelectedKelurahanContext);
  const defaultLogoLabel = useContext(LogoLabelContext);

  const [kecamatan, setKecamatan] = useState();
  const [kelurahan, setKelurahan] = useState();

  useEffect(() => {
    const id =
      kecamatan &&
      kecamatan.filter((item) => item.id === Number(selectedKecamatan))[0]?.id;

    const url = `${API_PORTAL}/kelurahan/kecamatan?id_kecamatan=${id}`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setKelurahan(json.data);
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }, [kecamatan, selectedKecamatan]);

  useEffect(() => {
    const url = `${API_PORTAL}/kecamatan`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setKecamatan(json.data);
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="mb-8">
        <h4 className="font-semibold">Catatan:</h4>
        <ul>
          <li className="text-xs text-primary mb-1">
            • Input dengan label bertanda * wajib diisi
          </li>
          <li className="text-xs text-primary mb-1">
            • Logo harus berekstensi .jpg, .jpeg, atau .png
          </li>
          <li className="text-xs text-primary mb-1">
            • Ukuran logo tidak boleh melebihi 512 KB
          </li>
        </ul>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="nama_organisasi" className="required mb-4">
          Nama Organisasi:
        </label>
        <input
          type="text"
          className="px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
          id="nama_organisasi"
          {...register("nama_organisasi")}
        />
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.nama_organisasi?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.nama_organisasi && errorAPI.nama_organisasi[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="nama_ketua" className="required mb-4">
          Nama Ketua:
        </label>
        <input
          type="text"
          className="px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
          id="nama_ketua"
          {...register("nama_ketua")}
        />
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.nama_ketua?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.nama_ketua && errorAPI.nama_ketua[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="alamat" className="required mb-4">
          Alamat:
        </label>
        <textarea
          className="h-44 px-4 py-2 border-2 border-[#C0C0C0] rounded-3xl focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
          id="alamat"
          {...register("alamat")}
        />
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.alamat?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.alamat && errorAPI.alamat[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-6 md:mb-8">
        <label htmlFor="logo" className="mb-4">
          Logo:
        </label>
        <div className="relative">
          <input
            type="file"
            className="opacity-0"
            id="logo"
            {...register("logo")}
            onChange={(e) => {
              setDefaultLogoLabel(e.target.files[0].name);
            }}
          />
          <label
            htmlFor="logo"
            className="px-4 py-2 border-2 border-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
          >
            {defaultLogoLabel}
          </label>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errors.logo?.message}
          </p>
          <p className="text-xs text-red-600 ml-2 mt-6">
            {errorAPI && errorAPI.logo && errorAPI.logo[0]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="kecamatan" className="required mb-4">
          Kecamatan:
        </label>
        <select
          className="appearance-none px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary bg-[url('/public/img/dropdown.svg')] bg-no-repeat bg-[right_15px_center]"
          id="kecamatan"
          {...register("kecamatan")}
          onChange={(e) => {
            setSelectedKecamatan(e.target.value);
          }}
          value={selectedKecamatan}
        >
          <option>Pilih Kecamatan</option>
          {kecamatan &&
            kecamatan.map((item) => (
              <option key={item.id} value={item.id}>
                {item.kecamatan}
              </option>
            ))}
        </select>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.kecamatan?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.kecamatan && errorAPI.kecamatan[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="kelurahan" className="required mb-4">
          Kelurahan:
        </label>
        <select
          className="appearance-none px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary bg-[url('/public/img/dropdown.svg')] bg-no-repeat bg-[right_15px_center]"
          id="kelurahan"
          {...register("kelurahan")}
          onChange={(e) => {
            setSelectedKelurahan(e.target.value);
          }}
          value={selectedKelurahan}
        >
          <option>Pilih Kelurahan</option>
          {kelurahan &&
            kelurahan.map((item) => (
              <option key={item.id} value={item.id}>
                {item.kelurahan}
              </option>
            ))}
        </select>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.kelurahan?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.kelurahan && errorAPI.kelurahan[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="no_hp" className="required mb-4">
          No. Hp:
        </label>
        <input
          type="text"
          className="px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
          id="no_hp"
          {...register("no_hp")}
        />
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.no_hp?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.no_hp && errorAPI.no_hp[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="email" className="required mb-4">
          Email:
        </label>
        <input
          type="text"
          className="px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
          id="email"
          {...register("email")}
        />
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.email?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.email && errorAPI.email[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="no_akta" className="required mb-4">
          No AHU/SKT:
        </label>
        <input
          type="text"
          className="px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
          id="no_akta"
          defaultValue={"-"}
          {...register("no_akta")}
        />
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.no_akta?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.no_akta && errorAPI.email[0]}
        </p>
      </div>
      <div className="flex flex-col justify-center mb-8">
        <label htmlFor="email" className="required mb-4">
          Berbadan Hukum:
        </label>
        <div className="flex justify-between w-[120px]">
          <div className="flex items-center">
            <input
              type="radio"
              className="mr-1 px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
              id="ya"
              value="1"
              {...register("berbadan_hukum")}
            />
            <label htmlFor="ya">Ya</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              className="mr-1 px-4 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
              id="tidak"
              value="0"
              {...register("berbadan_hukum")}
              defaultChecked
            />
            <label htmlFor="tidak">Tidak</label>
          </div>
        </div>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errors.berbadan_hukum?.message}
        </p>
        <p className="text-xs text-red-600 ml-2 mt-2">
          {errorAPI && errorAPI.berbadan_hukum && errorAPI.berbadan_hukum[0]}
        </p>
      </div>
    </>
  );
};

export default Identitas;
