import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect, createContext } from "react";

// Pages
import Home from "./components/Home";
import Permohonan from "./components/Permohonan";
import Reupload from "./components/Reupload";
import { showModalError } from "./components/Form/modalError";

import { API_AMOR } from "./config";

export const WebInfoContext = createContext();

function App() {
  const [orgInfo, setOrgInfo] = useState({
    id: 1,
    nama_kontak: "Supriati S.Sos",
    nomor_wa: "085378215100",
    nama_badan: "Bakesbangpol",
    nomor_kantor: "0717431476",
    alamat_kantor:
      "Jl. Basuki Rachmat, Kecamatan Girimaya Pangkalpinang, 33143",
    email_kantor: "kesbangpol@pangkalpinangkota.go.id",
    web_kantor: "kesbangpol.pangkalpinangkota.go.id",
    link_yt: "https://www.youtube.com/badankesbangpolpkp/",
    link_fb: "https://www.facebook.com/badankesbangpolpkp/",
    link_ig: "https://www.instagram.com/badankesbangpolpkp/",
  });

  useEffect(() => {
    const url = `${API_AMOR}/settings`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setOrgInfo(json.data);
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <WebInfoContext.Provider value={orgInfo}>
              <Home />
            </WebInfoContext.Provider>
          }
        />
        <Route path="*" element={<Home />} />
        <Route
          path="/permohonan"
          element={
            <WebInfoContext.Provider value={orgInfo}>
              <Permohonan />
            </WebInfoContext.Provider>
          }
        />
        <Route path="/reupload-berkas" element={<Reupload />} />
      </Routes>
    </Router>
  );
}

export default App;
