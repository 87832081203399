import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { showModalError } from "../Form/modalError";
import "react-loading-skeleton/dist/skeleton.css";

import OrmasItems from "./OrmasItems";
import OrmasModal from "./OrmasModal";
import { API_AMOR } from "../../config";

const Ormas = () => {
  // Search
  const [keyword, setKeyword] = useState("");
  const [isAnyKeyword, setIsAnyKeyword] = useState(false);
  const [ormas, setOrmas] = useState([]);
  const [singleOrmas, setSingleOrmas] = useState({});

  function searchHandler(e) {
    setKeyword(e.target.value);
  }

  useEffect(() => {
    if (keyword.length) {
      setIsAnyKeyword(true);
    } else {
      setIsAnyKeyword(false);
    }

    const url = `${API_AMOR}/ormas/verified?keyword=${keyword}`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setOrmas(json.data);
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }, [keyword]);

  function clearKeyword() {
    setKeyword("");
  }

  // Modal
  const [modalIsOpen, setIsOpen] = useState(false);

  function ormasHandler(ormasId) {
    openModal();
    getVerifiedOrmas(ormasId);
  }

  function getVerifiedOrmas(ormasId) {
    const url = `${API_AMOR}/ormas/verified/${ormasId}`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setSingleOrmas({ ...json.data });
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // Pagination
  // We start with an empty list of items.
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const url = `${API_AMOR}/ormas/verified`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setOrmas(json.data);
      } catch (err) {
        showModalError();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(ormas.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(ormas.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, ormas]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % ormas.length;
    setItemOffset(newOffset);
  };

  return (
    <section className="pt-12 md:pt-24 pb-12 md:pb-24" id="ormas">
      <div className="container">
        <div className="w-full px-4">
          <h1 className="font-semibold text-2xl text-primary text-center md:text-4xl mb-8 md:mb-16">
            Data Organisasi Kemasyarakatan
          </h1>
          <div className="flex mb-8 md:mb-16">
            <div className="flex mx-auto relative w-5/6 md:w-1/3 lg:w-1/4">
              <input
                type="text"
                className="w-full px-10 py-2 border-2 border-[#C0C0C0] rounded-full focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
                placeholder="Cari Ormas..."
                onChange={(e) => searchHandler(e)}
                value={keyword}
              />
              <img
                src="/img/magnifying-glass.svg"
                alt="magnifying glass"
                className="absolute translate-y-1/2 left-4 bottom-1/2"
              />
              {isAnyKeyword && (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-[#E4E4E4] hover:fill-primary absolute translate-y-1/2 right-4 bottom-1/2 cursor-pointer"
                  onClick={clearKeyword}
                >
                  <path d="M0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8ZM5.46875 6.53125L6.9375 8L5.46875 9.46875C5.15625 9.78125 5.15625 10.25 5.46875 10.5312C5.75 10.8438 6.21875 10.8438 6.5 10.5312L7.96875 9.0625L9.46875 10.5312C9.75 10.8438 10.2188 10.8438 10.5 10.5312C10.8125 10.25 10.8125 9.78125 10.5 9.46875L9.03125 8L10.5 6.53125C10.8125 6.25 10.8125 5.78125 10.5 5.46875C10.2188 5.1875 9.75 5.1875 9.46875 5.46875L7.96875 6.96875L6.5 5.46875C6.21875 5.1875 5.75 5.1875 5.46875 5.46875C5.15625 5.78125 5.15625 6.25 5.46875 6.53125Z" />
                </svg>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-center">
            {currentItems && currentItems.length ? (
              <OrmasItems
                currentItems={currentItems}
                ormasHandler={ormasHandler}
              />
            ) : (
              <h6 className="self-center">Data Tidak Ditemukan</h6>
            )}
          </div>
        </div>
      </div>

      <ReactPaginate
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        previousLabel="<"
        nextLabel=">"
        breakLabel=".."
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        activeClassName="bg-primary text-white"
        pageClassName="flex justify-center items-center mr-1 border-2 border-primary w-8 h-8 mt-4 text-primary"
        nextClassName="flex justify-center items-center mr-1 border-2 border-primary w-8 h-8 mt-4 text-primary rounded-r-lg"
        previousClassName="flex justify-center items-center mr-1 border-2 border-primary w-8 h-8 mt-4 text-primary rounded-l-lg"
        breakClassName="flex justify-center items-center mr-1 border-2 border-primary w-8 h-8 mt-4 text-primary"
        containerClassName="flex justify-center"
        disabledLinkClassName="opacity-50"
      />

      <OrmasModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        singleOrmas={singleOrmas}
      />
    </section>
  );
};

export default Ormas;
