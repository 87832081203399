import * as Yup from "yup";

const fileValidation = Yup.mixed()
  .test("required", "File wajib diisi", (value) => {
    return value && value.length > 0;
  })
  .test("fileType", "Format berkas tidak didukung", (value) => {
    return (
      value &&
      value[0] &&
      ["image/jpeg", "image/png", "image/jpg", "application/pdf"].includes(
        value[0].type
      )
    );
  })
  .test("fileSize", "Ukuran berkas tidak boleh melebihi 2MB", (value) => {
    return value && value[0] && value[0].size <= 2048000;
  });

export const validationSchema = Yup.object().shape({
  nama_organisasi: Yup.string().required("Nama organisasi wajib diisi"),
  nama_ketua: Yup.string().required("Nama ketua wajib diisi"),
  alamat: Yup.string().required("Alamat wajib diisi"),
  kecamatan: Yup.string()
    .required("Kecamatan wajib diisi")
    .test(
      "required",
      "Kecamatan wajib diisi",
      (value) => value !== "Pilih Kecamatan"
    ),
  kelurahan: Yup.string()
    .required("Kelurahan wajib diisi")
    .test(
      "required",
      "Kelurahan wajib diisi",
      (value) => value !== "Pilih Kelurahan"
    ),
  no_hp: Yup.string()
    .required("No Hp wajib diisi")
    .matches(
      // https://regex101.com/r/qtEg6H/3
      /(\()?(\+62|62|0)(\d{2,3})?\)?[ .-]?\d{2,4}[ .-]?\d{2,4}[ .-]?\d{2,4}/g,
      "No Hp tidak valid"
    ),
  email: Yup.string().required("Email wajib diisi").email("Email tidak valid"),
  no_akta: Yup.string().required("No Akta wajib diisi"),
  berbadan_hukum: Yup.string().required("Berbadan hukum wajib diisi"),
  file_1: fileValidation,
  file_2: fileValidation,
  file_3: fileValidation,
  file_4: fileValidation,
  file_5: fileValidation,
  file_6: fileValidation,
  file_7: fileValidation,
});
