import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { useEffect, useState } from "react";
import { API_AMOR } from "../config";

import NotFound from "./NotFound";
import Loader from "./Loader";
import { showModalError } from "./Form/modalError";

const Reupload = () => {
  const defaultLabelFiles = {
    file_1: "Upload berkas",
    file_2: "Upload berkas",
    file_3: "Upload berkas",
    file_4: "Upload berkas",
    file_5: "Upload berkas",
    file_6: "Upload berkas",
    file_7: "Upload berkas",
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();
  const [files, setFiles] = useState(defaultLabelFiles);
  const [isLoading, setIsLoading] = useState(false);
  const [errorAPI, setErrorAPI] = useState();

  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const kode = searchParams.get("kode");
    const url = `${API_AMOR}/dokumen/tidak-sesuai/${kode}`;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json.data);
      } catch (err) {
        setIsError(true);
      }
    };

    fetchData();
  }, [searchParams]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    // Prepare form data
    const formData = new FormData();
    const keys = Object.keys(data);
    keys.map((key) => {
      return formData.append(key, data[key][0]);
    });

    const url = `${API_AMOR}/dokumen/tidak-sesuai`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.status === 422) {
        const err = await response.json();
        setErrorAPI(err);
        setIsLoading(false);
        return;
      }

      if (response.status >= 400) {
        showModalError();
        setIsLoading(false);
        return;
      }

      setFiles(defaultLabelFiles);
      let timerInterval;
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        html: "Berhasil mengupload berkas",
        timer: 3000,
        timerProgressBar: true,
        willClose: () => {
          clearInterval(timerInterval);
          return navigate("/");
        },
      });
    } catch (err) {
      showModalError();
    }
    setIsLoading(false);
  };

  return (
    <>
      {isError ? (
        <NotFound />
      ) : (
        <div className="mx-auto mt-4 mb-6 md:mt-12 md:mb-16 px-6 md:w-3/5 lg:w-2/5 xl:w-1/3">
          <img
            src="/img/logo-amor.png"
            alt="logo amor"
            className="w-[180px] mx-auto"
          />
          <div className="mt-4 md:mt-12 mb-4 border-2 border-primary px-8 py-6 rounded-lg">
            <div className="mb-8">
              <h4 className="font-semibold">Catatan:</h4>
              <ul>
                <li className="text-xs text-primary mb-1">
                  • Format berkas harus berekstensi .jpg, .jpeg, .png, atau .pdf
                </li>
                <li className="text-xs text-primary mb-1">
                  • Ukuran berkas tidak boleh melebihi 2MB
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {data?.map((dokumen, index) => {
                return (
                  <div
                    className="flex flex-col justify-center mb-6 md:mb-8"
                    key={dokumen.id}
                  >
                    <label
                      htmlFor={`file_${index + 1}`}
                      className="required mb-4 text-xs md:text-sm"
                    >
                      {dokumen?.persyaratan}
                    </label>
                    <div className="relative">
                      <input
                        accept=".jpg, .png, .jpeg, .pdf"
                        type="file"
                        className="opacity-0"
                        id={`file_${index + 1}`}
                        {...register(`file_${dokumen.id}`)}
                        onChange={(e) => {
                          setFiles({
                            ...files,
                            [`file_${index + 1}`]: e.target.files[0].name,
                          });
                        }}
                      />
                      <label
                        htmlFor={`file_${index + 1}`}
                        className="px-4 py-2 border-2 border-[#C0C0C0] text-[#C0C0C0] rounded-full absolute top-0 left-0 w-full text-sm md:text-base truncate"
                      >
                        {files[`file_${index + 1}`]}
                      </label>
                      {errorAPI && (
                        <p className="text-xs text-red-600 ml-2 mt-6">
                          {errorAPI &&
                            errorAPI[`file_${dokumen.id}`] &&
                            errorAPI[`file_${dokumen.id}`][0]}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
              {isLoading ? (
                <Loader />
              ) : (
                <div className="flex justify-end">
                  <button className="font-medium text-sm md:text-base text-white bg-primary border-2 border-primary px-6 py-1 md:px-8 md:py-2 rounded-lg disabled:opacity-60 disabled:cursor-not-allowed hover:bg-white hover:text-primary focus:bg-white focus:text-primary focus:border-primary focus:border-2 focus:outline-none transition duration-300">
                    Kirim
                  </button>
                </div>
              )}
            </form>
          </div>
          <Link to="/" className="font-semibold text-primary">
            {"< Beranda"}
          </Link>
        </div>
      )}
    </>
  );
};

export default Reupload;
