const Hubungi = ({ orgInfo }) => {
  return (
    <section className="pt-12 md:pt-24 pb-12 md:pb-0" id="hubungi">
      <div className="container">
        <div className="w-full px-4">
          <h1 className="font-semibold text-2xl text-primary text-center mb-8 md:text-4xl md:mb-16">
            Hubungi Kami
          </h1>
          <div className="flex flex-col md:w-3/4 lg:full mx-auto">
            <div className="mb-12 lg:mr-4 order-2 lg:order-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1993.518830820397!2d106.11465390131252!3d-2.139243100000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e22c7799b8a4b49%3A0xa2a6882495c153f0!2sBADAN%20KESATUAN%20BANGSA%20DAN%20POLITIK%20KOTA%20PANGKALPINANG!5e0!3m2!1sen!2sus!4v1655436460929!5m2!1sen!2sus"
                className="w-full h-[400px] md:h-[450px] border-0 mx-auto"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="bakesbangpol location"
              ></iframe>
            </div>
            <div className="flex flex-col sm:flex-row order-1 lg:order-2 w-full sm:justify-around">
              <div className="mb-6 md:mb-12">
                <a
                  href={`https://wa.me/62${orgInfo?.nomor_wa.slice(
                    1
                  )}?text=Halo,%20saya%20ingin%20bertanya`}
                  id="prime"
                  className="flex justify-center lg:justify-start"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-8 mr-2"
                    src="/img/support.png"
                    alt="support"
                  />
                  <div className="text-lg">
                    <p className="font-semibold">{orgInfo?.nama_kontak}</p>
                    <p className="text-sm">
                      {orgInfo?.nomor_wa.match(/.{1,4}/g).join("-")}
                    </p>
                  </div>
                </a>
              </div>
              <div className="mb-6 md:mb-12">
                <a
                  href={`tel:${orgInfo?.nomor_kantor}`}
                  id="prime"
                  className="flex justify-center lg:justify-start"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-10 mr-2"
                    src="/img/phone.png"
                    alt="support"
                  />
                  <div className="text-lg">
                    <p className="font-semibold">{orgInfo?.nama_badan}</p>
                    <p className="text-sm">
                      {`(${orgInfo?.nomor_kantor.slice(
                        0,
                        4
                      )}) ${orgInfo?.nomor_kantor.slice(4)}`}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hubungi;
