import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const OrmasModal = ({ modalIsOpen, closeModal, singleOrmas }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="px-6 py-4">
        {singleOrmas.nama_organisasi ? (
          <h2 className="text-4xl font-black text-primary mb-2">
            {singleOrmas.nama_organisasi}
          </h2>
        ) : (
          <div className="w-2/3">
            <Skeleton className="h-10 mb-2" borderRadius="1.25rem" />
          </div>
        )}
        {singleOrmas.berbadan_hukum ? (
          <p className="text-xs text-[#C0C0C0] mb-4">
            {singleOrmas.berbadan_hukum === "1" && "Berbadan Hukum"}
          </p>
        ) : (
          <div className="w-1/3">
            <Skeleton className="h-4 mb-2" borderRadius="1.25rem" />
          </div>
        )}
        <div className="flex flex-col md:flex-row mb-8">
          <div className="pr-4">
            {singleOrmas.nama_ketua ? (
              <div className="flex mb-2">
                <img src="/img/user-tie.svg" alt="user tie" className="mr-2" />
                <p>{singleOrmas.nama_ketua}</p>
              </div>
            ) : (
              <div className="w-[160px]">
                <Skeleton className="h-6 mb-2" borderRadius="1.25rem" />
              </div>
            )}
            {singleOrmas.no_hp ? (
              <div className="flex mb-2">
                <img
                  src="/img/square-phone.svg"
                  alt="square phone"
                  className="mr-2"
                />
                <p>{singleOrmas.no_hp}</p>
              </div>
            ) : (
              <div className="w-[160px]">
                <Skeleton className="h-6 mb-2" borderRadius="1.25rem" />
              </div>
            )}
            {singleOrmas.email && (
              <div className="flex mb-2">
                <img
                  src="/img/square-envelope.svg"
                  alt="square envelope"
                  className="mr-2"
                />
                <p>{singleOrmas.email}</p>
              </div>
            )}
          </div>
          {singleOrmas.no_hp ? (
            <div className="flex mb-2 items-baseline">
              <svg
                width="9"
                height="13"
                viewBox="0 0 9 13"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-black mr-2"
              >
                <path d="M3.9375 12.2188C2.71875 10.6953 0 7.0625 0 5C0 2.51562 1.99219 0.5 4.5 0.5C6.98438 0.5 9 2.51562 9 5C9 7.0625 6.25781 10.6953 5.03906 12.2188C4.75781 12.5703 4.21875 12.5703 3.9375 12.2188ZM4.5 6.5C5.32031 6.5 6 5.84375 6 5C6 4.17969 5.32031 3.5 4.5 3.5C3.65625 3.5 3 4.17969 3 5C3 5.84375 3.65625 6.5 4.5 6.5Z" />
              </svg>

              <div>
                <p className="max-w-[24ch] md:max-w-[16ch]">
                  {singleOrmas.alamat}
                </p>
                <p>Kel. {singleOrmas.kelurahan}</p>
                <p>Kec. {singleOrmas.kecamatan}</p>
              </div>
            </div>
          ) : (
            <div className="w-[160px]">
              <Skeleton className="h-6 mb-2" borderRadius="1.25rem" count={3} />
            </div>
          )}
        </div>
        {singleOrmas.nama_ketua ? (
          <button
            onClick={closeModal}
            className="float-right border-2 border-primary bg-white px-8 py-1 rounded-lg font-semibold text-primary hover:text-white hover:bg-primary duration-300"
          >
            Tutup
          </button>
        ) : (
          <div className="w-[80px] float-right">
            <Skeleton className="h-8 mb-2" borderRadius="1.25rem" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OrmasModal;
