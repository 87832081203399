const Footer = ({ orgInfo }) => {
  return (
    <footer>
      <img src="/img/wave.png" alt="wave" className="w-full" />
      <div className="bg-primary pt-12 md:pt-0 pb-12 -mt-1">
        <div className="container">
          <div className="flex items-center">
            <div className="w-full px-4">
              <img
                src="/img/logo-amor-white.png"
                alt="logo amor"
                className="w-[160px] mb-6 m-auto md:mx-0"
              />
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 xl:w-1/3 mb-6">
                  <p className="font-medium text-xl text-white text-center md:text-left mx-auto md:mx-0 mb-6">
                    Aplikasi Melapor Ormas
                  </p>
                  <p className="font-medium text-sm text-white md:text-left leading-relaxed mb-6">
                    {orgInfo?.alamat_kantor}
                  </p>
                  <div className="font-medium text-sm text-white leading-loose">
                    <p>
                      Phone/Fax :{" "}
                      {`(${orgInfo?.nomor_kantor.slice(
                        0,
                        4
                      )}) ${orgInfo?.nomor_kantor.slice(4)}`}
                    </p>
                    <p>Email :{orgInfo?.email_kantor}</p>
                    <p>Web : {orgInfo?.web_kantor}</p>
                  </div>
                </div>
                <div className="flex flex-col xl:flex-row md:1/2">
                  <img
                    src="img/logo-footer.png"
                    alt="logo footer"
                    className="mb-6 order-1 md:order-2 xl:order-1"
                  />
                  <div className="md:ml-10 order-2 md:order-1 xl:order-2">
                    <p className="font-semibold text-sm text-white text-center md:text-left">
                      Ikuti kami di:
                    </p>
                    <div className="flex justify-between w-[150px] mx-auto md:mx-0 mb-6">
                      <a
                        href={orgInfo?.link_fb}
                        target="_blank"
                        rel="noreferrer"
                        className="focus:opacity-80 focus:outline-none"
                      >
                        <img
                          src="/img/facebook-f.svg"
                          className="mt-2"
                          alt="facebook"
                        />
                      </a>
                      <a
                        href={orgInfo?.link_ig}
                        target="_blank"
                        rel="noreferrer"
                        className="focus:opacity-80 focus:outline-none"
                      >
                        <img
                          src="/img/instagram.svg"
                          className="mt-2"
                          alt="instagram"
                        />
                      </a>
                      <a
                        href={orgInfo?.link_yt}
                        target="_blank"
                        rel="noreferrer"
                        className="focus:opacity-80 focus:outline-none"
                      >
                        <img
                          src="/img/youtube.svg"
                          className="mt-2"
                          alt="youtube"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="border-t border-white mt-10 mb-6" />
              <p className="text-xs text-white text-center">
                Copyright 2022 Diskominfo dan Bakesbangpol Kota Pangkalpinang -
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
      <a
        id="pageUp"
        href="#home"
        className="w-12 h-12 md:w-16 md:h-16 bg-primary border border-white rounded-full hidden items-center fixed cursor-pointer bottom-2 md:bottom-5 right-0 md:right-5 -translate-x-[10px] md:-translate-x-1/2"
      >
        <span className="mx-auto cursor-pointer">
          <img src="/img/arrow-up-solid.svg" alt="arrow-up" className="w-4" />
        </span>
      </a>
    </footer>
  );
};

export default Footer;
